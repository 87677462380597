import React, {FC, useEffect, useRef, useState} from "react";

import ItemOrder from "../../components/ItemOrder";
import {AXI} from "../../libs/axi";
import {useParams} from 'react-router-dom';
import s from "./Categories.module.css"
import {SearchForm} from "../../components/SearchForm";
import RecomendationItem from "../../components/ModalFormItem/RecomendationItem";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import Layout from "../../components/Layout";
import {ResponseData, Item} from "../../types/types";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import Preloader from "../Preloader";
import Icon from "../Icon";
import useObjectModal from "../../hooks/useObjectModal";


interface ObjectModal {
    id: number;
    count: number;
}

interface Modal {
    // objectModal: ObjectModal[],
    // setObjectModal: React.Dispatch<React.SetStateAction<ObjectModal[]>>
    modalCategories?: string | null
    modalPodbor?: string | null
    modalActions?: string | null
}

const ModalCategories: FC<Modal> = ({modalCategories = "", modalPodbor = "", modalActions = "",}) => {
    // const {id: nameId} = useParams();
    const { searchText } = useGetLocationData();
    const [arrCat, setArrCat] = useState<Item[]>([])
    const [text, setText] = useState<string>("")
    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const { objectModal, addObjectModalItem } = useObjectModal(searchText);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (fetching) {
            if (modalCategories) {
                fetch(`${AXI}/api/items/catitems?category=${modalCategories}&count=20&page=${page}`)
                    .then(res => res.json())
                    .then((data) => {

                            setArrCat([...arrCat, ...data["items"]]);

                            // setText(data.name)
                            setPage(prevState => prevState + 1)

                        },
                    ).finally(() => setFetching(false))
            }

            if (modalPodbor) {
                fetch(`${AXI}/api/items/specialcatitems?id=${modalPodbor}&count=20&page=${page}`)
                    .then(res => res.json())
                    .then((data) => {

                            setArrCat([...arrCat, ...data["items"]]);

                            // setText(data.name)
                            setPage(prevState => prevState + 1)

                        },
                    ).finally(() => setFetching(false))
            }

            if (modalActions) {
                fetch(`${AXI}/api/items/actiontitems?id=${modalActions}&count=20&page=${page}`)
                    .then(res => res.json())
                    .then((data) => {

                            setArrCat([...arrCat, ...data["items"]]);

                            // setText(data.name)
                            setPage(prevState => prevState + 1)

                        },
                    ).finally(() => setFetching(false))
            }

        }

    }, [modalCategories, modalPodbor, modalActions, fetching])

    useEffect(() => {
        setIsLoading(true);
        if (modalCategories) {
            fetch(`${AXI}/api/items/catitems?category=${modalCategories}&count=20&page=1`)
                .then(res => res.json())
                .then((data) => {

                        setText(data["category"].name)
                        setArrCat([...data["items"]]);
                        setPage(prevState => prevState + 1)
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 1000);
                    },
                ).finally(() => setFetching(false)).catch(error => {
                console.log(error)
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000);
            })
        }
        if (modalPodbor) {
            fetch(`${AXI}/api/items/specialcatitems?id=${modalPodbor}&count=20&page=1`)
                .then(res => res.json())
                .then((data) => {

                        setText(data["category"].name)
                        setArrCat([...data["items"]]);
                        setPage(prevState => prevState + 1)
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 1000);
                    },
                ).finally(() => setFetching(false)).catch(error => {
                console.log(error)
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000);
            })
        }
        if (modalActions) {
            fetch(`${AXI}/api/items/actiontitems?id=${modalActions}&count=20&page=1`)
                .then(res => res.json())
                .then((data) => {

                        setText(data["category"].name)
                        setArrCat([...data["items"]]);
                        setPage(prevState => prevState + 1)
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 1000);
                    },
                ).finally(() => setFetching(false)).catch(error => {
                console.log(error)
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000);
            })
        }


    }, [modalCategories, modalPodbor, modalActions])

    const handleScroll = (e: any) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
            setFetching(true)
        }
    };



    const setData = useSetLocationData();

    const handleClickRes = (id: number) => {
        addObjectModalItem(id,1)
    };

    return (<div id={"wrap"} className={s.modalAll}>{
            isLoading ? <Preloader/> : <>
                <div className={s.wrapper} ref={containerRef}>

                    <div className={s.title}>
                        <div className={s.Arrow}
                             onClick={() => {
                                 setData(["modalCategories", ""], ["modalPodbor", ""], ["modalActions", ""], ["titleModal", ""])
                                 setArrCat([])
                             }}>
                            <Icon.Arrow/>
                        </div>
                        {text}
                    </div>
                    {arrCat.length > 0 && arrCat.map(({id, name, img, price, discount, groupModifiers,discountprocent,hot}, index) =>
                        <ItemOrder
                            key={`${id}${name}${index}2`}
                            id={id}
                            name={name}
                            hot={hot}
                            img={img}
                            price={price}
                            discount={discount}
                            groupModifiers={groupModifiers}
                            discountprocent={discountprocent}
                            handleClickRes={handleClickRes}
                            objectModal={objectModal}
                        />
                    )}
                </div>
            </>
        } </div>
    )
}


export default ModalCategories;
