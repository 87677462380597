import React, {useEffect, useState} from 'react';
import {Routes, Route, Navigate, useNavigate, BrowserRouter} from 'react-router-dom';
import './fonts/stylesheet.css';
import useLocalStorage from "use-local-storage";
import localforage from 'localforage';
import Homepage from './pages/Homepage';
import Main from './pages/Main';
import Tv from './pages/Tv';
import Tv2 from './pages/Tv/tv2';
import Tv3 from './pages/Tv/tv3';
import Sale from './pages/Sale';
import Table from './pages/Sale/Table';
import LoginPage from './pages/LoginPage';
import {Actions} from "./pages/Actions";
import {MenuPage} from "./pages/MenuPage";
import {Order} from "./pages/Order";
import SpecialCat from "./pages/SpecialCat";
import Categories from "./pages/Categories";
import ActionCat from "./pages/ActionCat";
import {MenuBottom} from "./components/MenuBottom/MenuBottom";
import ModalFormItem from "./components/ModalFormItem";
import s from "./App.css"
import {useGetLocationData, useSetLocationData} from "./hooks/locationData";
import {Theme, ThemeProvider} from "./theme/ThemeContext";
import {getRestoranMini} from "./api/restoran";

interface ObjectModal {
    id: number;
    count: number;
}

interface ItemCatigories {

    objectModal?: ObjectModal[];
}

interface ItemRestoran {
    hookah: boolean,
    open: boolean,
    name: string,
    darktheme: boolean
}

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [isModal, setIsModal] = useState<string | null | undefined>(undefined);
    const [isTotal, setIsTotal] = useState<string | null | undefined>(undefined);
    const [restoran, setRestoran] = useState({} as ItemRestoran);
    const navigate = useNavigate();
    const handleLogin = () => {
        // Логика аутентификации
        setIsAuthenticated(true);
    };
    const { searchText, modal } = useGetLocationData();
    const setData = useSetLocationData();
    const handleLogout = () => {
        // Логика выхода
        setIsAuthenticated(false);
    };

    const PrivateRoute = ({element: Component, ...rest}: { element: React.ComponentType }) => {

        return isAuthenticated ? (
            <Component {...rest} />
        ) : (
            <Navigate to="/login" replace={true}/>
        );
    };

    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // // const modal = urlParams.get('modal');
    //
    // const currentPath = window.location.pathname;


    // useEffect(() => {
    //     setIsModal(modal)
    // }, [modal])

    const [objectModal, setObjectModal] = useState<ObjectModal[]>([]);


    useEffect(() => {
        if(searchText){
            const savedData = localStorage.getItem('objectModal');


            if (savedData) {
                try {
                    const parsedData = JSON.parse(savedData);
                    if (Array.isArray(parsedData)) {

                        if (!arraysEqual(objectModal, parsedData)) {
                            setObjectModal(parsedData);
                            console.log('objectModal в localStorage был изменен.');
                        }
                    } else {
                        console.error('Invalid format for objectModal in localStorage.');
                    }
                } catch (error) {
                    console.error('Error parsing objectModal from localStorage:', error);
                }
            }
        } else {
            setData(["searchText","next"])
        }

    }, [searchText,objectModal,modal]);



    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');


        if (savedData) {
            try {
                const parsedData = JSON.parse(savedData);
                if (Array.isArray(parsedData)) {
                    // Проверяем, что компонент уже завершил инициализацию
                    if (objectModal.length === 0) {
                        setObjectModal(parsedData);
                    }

                } else {
                    console.error('Invalid format for objectModal in localStorage.');
                }
            } catch (error) {
                console.error('Error parsing objectModal from localStorage:', error);
            }
        }
    }, []);

    function arraysEqual(arr1:ObjectModal[], arr2:ObjectModal[]) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    let limit: number = 7200 * 1000;
    let localStorageInitTime: number | null = Number(localStorage.getItem('localStorageInitTime'));
    let currentTime: number = Date.now();

    if (localStorageInitTime === null || isNaN(localStorageInitTime) || localStorageInitTime === 0) {
        localStorage.setItem('localStorageInitTime', String(currentTime));
    } else if (currentTime - localStorageInitTime > limit) {
        localStorage.clear();
    }
    useEffect(() => {
        getRestoranMini().then((res: any) => setRestoran(res.data));
    }, []);
    const [isBlack, setIsBlack] = useState(restoran.darktheme)

    useEffect(()=>{
        setIsBlack(restoran.darktheme)
    },[restoran])



    return (
        <ThemeProvider initialTheme={isBlack ? Theme.BLACK : Theme.WHITE}>

        <div className={s.app} id={"App"}>

            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/?searchText=" element={<Main/>}/>

                <Route path="/actions" element={<Actions/>}/>
                <Route path="/menupage" element={<MenuPage/>}/>
                <Route path="/order" element={<Order isHookah={restoran.hookah} restoranName={restoran.name} />}/>

            </Routes>



            <MenuBottom
                objectModal={objectModal}
                setIsBlack={setIsBlack}
                isBlack={isBlack}
                isHookah={restoran.hookah}
                // currentPath={currentPath}
            />
        </div>

        </ThemeProvider>
    );
}

export default App;